<template>
  <div>
    <div ref="target">
      <slot />
    </div>

    <transition name="fade-popper">
      <div class="password-validation" v-show="show" ref="tooltip">
        <div class="password-validation-content">
          <span>Password must contain:</span>
          <ul>
            <li v-for="item of items" :key="item.message" :class="item.result ? 'has-success' : 'has-error'">
              <div><icon name="ic_check" /></div>
              <span>{{ item.message }}</span>
            </li>
          </ul>
        </div>
        <div class="arrow" data-popper-arrow></div>
      </div>
    </transition>
  </div>
</template>

<script>
import Icon from "@/libs/Icon";
import { createPopper } from '@popperjs/core';

export default {
  name: 'PasswordValidationHelper',
  props: {
    value: {
      type: String,
      default: ''
    },
    placement: {
      type: String,
      default: 'right-start'
    },
    show: {
      type: Boolean,
      default: false
    },
    offset: {
      type: Array,
      default: () => [0, 20]
    },
    fallbackPlacements: {
      type: Array,
      default: () => ['left', 'top', 'bottom']
    }
  },
  components: {
    Icon
  },
  data () {
    return {
      popper: null,
      popperOptions: null,

      validators: [
        {
          regexp: /^.{8,30}$/,
          message: 'From 8 to 30 characters'
        },
        {
          regexp: /[a-z]+/,
          message: 'At least one lowercase letter (a-z)'
        },
        {
          regexp: /[A-Z]+/,
          message: 'At least one uppercase letter (A-Z)'
        },
        {
          regexp: /[0-9]+/,
          message: 'At least one number (0-9)'
        },
        {
          /* eslint-disable no-useless-escape */
          regexp: /[!"#;:?\\*()+=/&,.\[\]{}]+/,
          message: "At least one special character (!\"#;:?\\*()+=/&,.[]{})"
        }
      ]
    }
  },
  computed: {
    items () {
      let errors = []

      for (let validator of this.validators) {
        let item = {...validator}
        item.result = validator.regexp.test(this.value)
        errors.push(item)
      }

      return errors
    }
  },
  watch: {
    show () {
      this.popper.update()
    }
  },
  created() {
    this.popperOptions = {
      placement: this.placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: this.offset,
          },
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: this.fallbackPlacements,
          },
        },
        {
          name: 'hide'
        }
      ],
    }
  },
  mounted() {
    this.popper = createPopper(this.$refs.target, this.$refs.tooltip, this.popperOptions)
    window.addEventListener('resize', this.updatePopper)
  },
  destroyed() {
    this.popper.destroy()
    window.removeEventListener('resize', this.updatePopper)
  },
  methods: {
    updatePopper () {
      this.popper.update()
    }
  }
}
</script>
<style lang="less">
.password-validation {
  position: absolute;

  width: auto;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: #19191C;
  box-shadow: 0 9px 18px 0 rgba(26, 26, 26, 0.18);
  z-index: 1;

  @offset: 20px;

  &-content {
    padding: 20px;
    z-index: 3;
    position: relative;
    background: #19191C;

    & > span {
      display: block;

      font-size: 13px;
      line-height: 16px;
      font-weight: 500;
      color: rgba(255, 255, 255, .6);
    }

    ul {
      font-size: 14px;

      z-index: 4;
      line-height: 1.4em;
      display: flex;
      flex-direction: column;

      li {
        margin-top: 15px;
        display: flex;
        color: rgba(255, 255, 255, .6);
        transition: all .2s linear;

        align-content: center;
        align-items: center;

        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        svg {
          margin-right: 10px;

          background: #C1C1C1;
          border-radius: 50%;
          padding: 2px;

          fill: #fff;
          transition: all .2s linear;
          top: 1px;
          position: relative;
        }

        &.has-success {
          color: #fff;

          svg {
            background: #fde46a;

            polygon {
              fill: #19191C;
            }
          }
        }
      }
    }
  }

  & > .arrow {
    position: absolute;
    content: ' ';
    display: block;

    z-index: 2;

    &:before {
      display: block;
      transform: rotate(45deg);
      background: #19191C;
      border: 1px solid rgba(255, 255, 255, 0.25);
      box-shadow: 0 9px 18px 0 rgba(26, 26, 26, 0.18);
      width: 12px;
      height: 12px;
      content: '';
    }
  }

  &[data-popper-placement^='top'] > .arrow {
    bottom: -7px;
  }

  &[data-popper-placement^='bottom'] > .arrow {
    top: -7px;
  }

  &[data-popper-placement^='left'] > .arrow {
    right: -7px;
  }

  &[data-popper-placement^='right'] > .arrow {
    left: -7px;
  }

  @media all and (max-width: 768px) {
    visibility: hidden;
    pointer-events: none;
  }
}

.fade-popper-enter-active,
.fade-popper-leave-active {
  transition: opacity .2s;
}
.fade-popper-enter,
.fade-popper-leave-to {
  opacity: 0;
}
</style>
